




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';
import { TextareaField, IFormField } from 'client-website-ts-library/types/Forms/FieldTypes';

import ErrorList from '../ErrorList.vue';

@Component({
  components: {
    ErrorList,
  },
})
export default class FormTextareaField extends Vue implements IFormField {
  @Prop()
  private readonly def!: TextareaField;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  private value: string | null = null;

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get elemId() {
    return `field_${this.def.FieldId}`;
  }

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: this.value,
    };
  }

  setValue(value: FormValue) {
    if (typeof value.Value === 'string' || value.Value === null) {
      this.value = value.Value;
    }
  }

  handleInput(): void {
    this.$emit('input', this.def);
  }
}
