










import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormSubmissionValidationError } from 'client-website-ts-library/types/Forms';

@Component
export default class ErrorList extends Vue {
  @Prop()
  private readonly errors!: FormSubmissionValidationError[];
}
